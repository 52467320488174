import React, {useContext} from 'react'
import {MyContext} from '../../../contexts/MyContext'

// Importing the Login & Register Componet
import Register from '../../Register'


function Softcivico() {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {
    return (
            <div className="content-wrapper">
    
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h4 className="m-0">SOFTCIVICO</h4>
          </div>
          
        </div>
      </div>
    </div>
   

   
    <section className="content">
      <div className="container-fluid">
        
        <div className="row">
        
       
          <div className="col-md-12">

<div className="callout callout-info">
<small className="float-right">Fecha: 9/11/2021</small>
    <h5><i className="fas fa-info"></i> DATOS DE ACCESO:</h5>
     <br/>
    <b>Usuario: </b> admin@gmail.com <br />
    <b>Contraseña: </b> 123456789 <b/> <br/> 
    <b><a href="https://miltonesgarantia.com.co/" target="blank">MILTONESGARANTIA.COM.CO</a> </b> <br/>
    <div id="qrcode">
<img src="https://www.codigos-qr.com/qr/php/qr_img.php?d=https%3A%2F%2Fmega.nz%2Ffile%2Fewh2mLTa%23bnFjRoUUwKQbbsJ2GanothdHoQ9vZcxCJag1qvKygKU&s=6&e=m" alt="Generador de Códigos QR Codes"/>
<br/><a href="https://www.codigos-qr.com/en/qr-code-generator/" target="_blank"></a>
</div><br/>

<b>PWA: </b> <a href="https://app.miltonesgarantia.com.co/" target="blank"> https://app.miltonesgarantia.com.co/ </a> <br /><br /><br />


<h5><i className="fas fa-info"></i> DATOS DE ACCESO:</h5>
   <br/>
    <b>Usuario: </b> admin <br />
    <b>Contraseña: </b> demo <b/> <br/> 
    <b><a href="https://votos.miltonesgarantia.com.co/" target="blank">VOTOS.MILTONESGARANTIA.COM.CO</a> </b> <br/>
    <div id="qrcode">
<img src="https://www.codigos-qr.com/qr/php/qr_img.php?d=https%3A%2F%2Fmega.nz%2Ffile%2FDoRygZDS%23qwlITijDP9L9Ro4sGZWDg0eKJNK7drX-01xA-mfS7qU&s=6&e=m" alt="Generador de Códigos QR Codes"/>
<br/><a href="https://www.codigos-qr.com/en/qr-code-generator/" target="_blank"></a>
</div>

<br/> Hola <b>Maria del pilar</b> soy <b>"GIO10"</b> tus apps estan en proceso de publicación en la playstore android y ios , apenas esten listas te envio los QR 
<br/> ANDROID - <b>Esperando aprobación por la tienda</b> <br/>
IPHONE - <b>Esperando aprobación por la tienda</b>    
  </div>

 

</div>

         
        </div>
      

        

      </div>
    </section>
    
  </div>
    )
    }

    else if(showLogin){
        return "";
    }
    else{
        return <Register/>;
    }
    
    }

export default Softcivico
