import React, {useState,useContext} from 'react';

import {Link} from 'react-router-dom';

import '../../../App.css';
import {MyContext} from '../../../contexts/MyContext'




function Menu(props) {
  const {rootState,logoutUser} = useContext(MyContext);

  
  const [dropdown, setDropdown]=useState(false);
  const {isAuth,theUser,showLogin} = rootState;

  const abrirCerrarDropdown=()=>{
    setDropdown(!dropdown);
  }
    
  return (
    <nav className="navbar  main-sidebar sidebar-light-primary elevation-4 " >
  
  
   <center>
  <a href="#" className="brand-link">
      <img src="dist/img/plantilla/logo.png" alt="multigobernanza" width="63px" />
      <span className="brand-text font-weight-light"></span>
    </a>   </center>  

   
    <div className=" scrollb ">
      <div className="user-panel mt-3 pb-3 mb-3 d-flex">
        <div className="image">
          <img src="dist/img/plantilla/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image"  width="170px" height="170px" />
        </div>
        <div className="info">
          <a href="#" className="d-block">{theUser.name}</a>
          
         
            <button className="btn btn-primary" onClick={logoutUser} > Salir <i className="fas fa-sign-out-alt"></i></button>
            
            
            
        </div>
      </div>

      <nav className="mt-2">
    
   
      <ul className="nav navbar-nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
      
      <li className="nav-item" >
            <Link to="/Noticias" className="nav-link">
            
              <i> <img src="dist/img/iconos/noticias.png" width="35px" /></i>
              <p>
                 &nbsp; Noticias
               <b className="caret" />
             </p>
              </Link>
          </li>

          <li className="nav-item" >
            <Link to="/Multigobernanza" className="nav-link">
            
              <i> <img src="dist/img/iconos/multigobernanza.png" width="35px" /></i>
              <p>
                 &nbsp; Dominios
               <b className="caret" />
             </p>
              </Link>
          </li>


          <li className="nav-item" >
            <Link to="/Cpanels" className="nav-link">
            
              <i> <img src="dist/img/iconos/Cpanel.png" width="35px" /></i>
              <p>
                 &nbsp; Cpanels
               <b className="caret" />
             </p>
              </Link>
          </li>
     

         <li className="dropdown nav-item menu-open">
          <a href="#" className="nav-link " data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
          <i> <img src="dist/img/iconos/butyc.png" width="35px" /></i>
          <p>
          &nbsp; Butyc
                <i className="right fas fa-angle-left"></i>
              </p>
          </a>
          <ul className="dropdown-menu">
          <li className="nav-item">
            <Link to="/Butyc" className="nav-link">
            <i> <img src="dist/img/iconos/web.png" width="35px" /></i>
            <p>
               &nbsp;Butyc
             <b className="caret" />
           </p>
            </Link>
        </li>
        <li className="nav-item">
            <Link to="/Butyc_clon" className="nav-link">
            <i> <img src="dist/img/iconos/movil.png" width="35px" /></i>
            <p>
               &nbsp;Crear clon
             <b className="caret" />
           </p>
            </Link>
        </li> 
        <li className="nav-item">
            <a href="https://www.portal.multigobernanza.com/manuales/manual_butyc.pdf" target="blank" className="nav-link">
            <i> <img src="dist/img/iconos/manual.png" width="35px" /></i>
            &nbsp;Manual </a>
        </li>
        <li className="nav-item">
          <a href="https://www.youtube.com/playlist?list=PLfGQWduGpHYJCMQhz1qvNhd8uMwLB06eq" target="blank" className="nav-link" >
            
            <i> <img src="dist/img/iconos/tutoriales.png" width="35px" /></i>
            <p>
               &nbsp;Tutoriales
             <b className="caret" />
           </p>
           </a>
        </li>
   
          </ul>
        </li>


        <li className="dropdown nav-item menu-open">
          <a href="#" className="nav-link " data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
          <i> <img src="dist/img/iconos/compronline.png" width="35px" /></i>
          <p>
          &nbsp; Compronline
                <i className="right fas fa-angle-left"></i>
              </p>
          </a>
          <ul className="dropdown-menu">
          <li className="nav-item">
            <Link to="/Compronline" className="nav-link">
            <i> <img src="dist/img/iconos/web.png" width="35px" /></i>
            <p>
               &nbsp;Compronline
             <b className="caret" />
           </p>
            </Link>
        </li>
        <li className="nav-item">
            <Link to="/Compronline_clon" className="nav-link">
            <i> <img src="dist/img/iconos/movil.png" width="35px" /></i>
            <p>
               &nbsp;Crear clon
             <b className="caret" />
           </p>
            </Link>
        </li> 
        <li className="nav-item">
            <a href="https://www.portal.multigobernanza.com/manuales/manual_compronline.pdf" target="blank" className="nav-link">
            <i> <img src="dist/img/iconos/manual.png" width="35px" /></i>
            &nbsp;Manual </a>
        </li>
        <li className="nav-item">
          <a href="https://www.youtube.com/playlist?list=PLfGQWduGpHYIrCuwFuBX-B7ebni9bWzXs" target="blank" className="nav-link" >
            
            <i> <img src="dist/img/iconos/tutoriales.png" width="35px" /></i>
            <p>
               &nbsp;Tutoriales
             <b className="caret" />
           </p>
           </a>
        </li>
   
          </ul>
        </li>


        <li className="dropdown nav-item menu-open">
          <a href="#" className="nav-link " data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
          <i> <img src="dist/img/iconos/softciudadano.png" width="35px" /></i>
          <p>
          &nbsp; Softciudadano
                <i className="right fas fa-angle-left"></i>
              </p>
          </a>
          <ul className="dropdown-menu">
          <li className="nav-item">
            <Link to="/Softciudadano" className="nav-link">
            <i> <img src="dist/img/iconos/web.png" width="35px" /></i>
            <p>
               &nbsp;Softciudadano
             <b className="caret" />
           </p>
            </Link>
        </li>
        <li className="nav-item">
            <Link to="/Softciudadano_clon" className="nav-link">
            <i> <img src="dist/img/iconos/movil.png" width="35px" /></i>
            <p>
               &nbsp;Crear clon
             <b className="caret" />
           </p>
            </Link>
        </li>
        <li className="nav-item">
            <a href="https://www.portal.multigobernanza.com/manuales/manual_softciudadano.pdf" target="blank" className="nav-link">
            <i> <img src="dist/img/iconos/manual.png" width="35px" /></i>
            &nbsp;Manual </a>
        </li>
        <li className="nav-item">
            <Link to="/Administradores" className="nav-link">
            <i> <img src="dist/img/iconos/tutoriales.png" width="35px" /></i>
            <p>
               &nbsp;Tutoriales
             <b className="caret" />
           </p>
            </Link>
        </li>
   
          </ul>
        </li>

        <li className="dropdown nav-item menu-open">
          <a href="#" className="nav-link " data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
          <i> <img src="dist/img/iconos/datos.png" width="35px" /></i>
          <p>
          &nbsp; Protección de datos
                <i className="right fas fa-angle-left"></i>
              </p>
          </a>
          <ul className="dropdown-menu">
          <li className="nav-item">
            <Link to="/Protecciondedatos" className="nav-link">
            <i> <img src="dist/img/iconos/web.png" width="35px" /></i>
            <p>
               &nbsp;Protección de datos
             <b className="caret" />
           </p>
            </Link>
        </li>
        
        <li className="nav-item">
            <Link to="/Protecciondedatos_clon" className="nav-link">
            <i> <img src="dist/img/iconos/movil.png" width="35px" /></i>
            <p>
               &nbsp;Crear clon
             <b className="caret" />
           </p>
            </Link>
        </li>
        
        <li className="nav-item">
            <a href="https://www.portal.multigobernanza.com/manuales/manual_habeasdata.pdf" target="blank" className="nav-link">
            <i> <img src="dist/img/iconos/manual.png" width="35px" /></i>
            &nbsp;Manual </a>
        </li>
        <li className="nav-item">
            <Link to="/Administradores" className="nav-link">
            <i> <img src="dist/img/iconos/tutoriales.png" width="35px" /></i>
            <p>
               &nbsp;Tutoriales
             <b className="caret" />
           </p>
            </Link>
        </li>
   
          </ul>
        </li>


        {/*<li className="dropdown nav-item menu-open">
          <a href="#" className="nav-link " data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
          <i> <img src="dist/img/iconos/votwin.png" width="35px" /></i>
          <p>
          &nbsp; Votwin
                <i className="right fas fa-angle-left"></i>
              </p>
          </a>
          <ul className="dropdown-menu">
          <li className="nav-item">
            <Link to="/Votwin" className="nav-link">
            <i> <img src="dist/img/iconos/web.png" width="35px" /></i>
            <p>
               &nbsp;Votwin
             <b className="caret" />
           </p>
            </Link>
  </li> 

        <li className="nav-item">
            <Link to="/Votwin_clon" className="nav-link">
            <i> <img src="dist/img/iconos/movil.png" width="35px" /></i>
            <p>
               &nbsp;Crear clon
             <b className="caret" />
           </p>
            </Link>
        </li>
        
  

       <li className="nav-item">
            <a href="https://www.portal.multigobernanza.com/manuales/manual_votwin.pdf" target="blank" className="nav-link">
            <i> <img src="dist/img/iconos/manual.png" width="35px" /></i>
            &nbsp;Manual </a>
        </li>
        <li className="nav-item">
          <a href="https://www.youtube.com/playlist?list=PLfGQWduGpHYIlXBV4APQhhF0Rpf81Hr-n" target="blank" className="nav-link" >
            
            <i> <img src="dist/img/iconos/tutoriales.png" width="35px" /></i>
            <p>
               &nbsp;Tutoriales
             <b className="caret" />
           </p>
           </a>
        </li>
          </ul>

        </li>   
  */}

       {/* <li className="dropdown nav-item menu-open">
          <a href="#" className="nav-link " data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
          <i> <img src="dist/img/iconos/clon.png" width="35px" /></i>
          <p>
          &nbsp; Softcivico
                <i className="right fas fa-angle-left"></i>
              </p>
          </a>
          <ul className="dropdown-menu">
          <li className="nav-item">
            <Link to="/Softcivico" className="nav-link">
            <i> <img src="dist/img/iconos/clon.png" width="35px" /></i>
            <p>
               &nbsp;Softcivico
             <b className="caret" />
           </p>
            </Link>
        </li>

        <li className="nav-item">
            <Link to="/Softcivico_clon" className="nav-link">
            <i> <img src="dist/img/iconos/movil.png" width="35px" /></i>
            <p>
               &nbsp;Crear clon
             <b className="caret" />
           </p>
            </Link>
        </li>
        
        
        <li className="nav-item">
            <a href="https://www.portal.multigobernanza.com/manuales/manual_softcivico.pdf" target="blank" className="nav-link">
            <i> <img src="dist/img/iconos/manual.png" width="35px" /></i>
            &nbsp;Manual </a>
        </li>
        <li className="nav-item">
            <Link to="/Administradores" className="nav-link">
            <i> <img src="dist/img/iconos/tutoriales.png" width="35px" /></i>
            <p>
               &nbsp;Tutoriales
             <b className="caret" />
           </p>
            </Link>
        </li>
          </ul>

        </li>   */}


      {/*  <li className="dropdown nav-item menu-open">
          <a href="#" className="nav-link " data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
          <i> <img src="dist/img/iconos/ganep.png" width="35px" /></i>
          <p>
          &nbsp; Ganosoft
                <i className="right fas fa-angle-left"></i>
              </p>
          </a>
          <ul className="dropdown-menu">
          <li className="nav-item">
            <Link to="/Ganosoft" className="nav-link">
            <i> <img src="dist/img/iconos/web.png" width="35px" /></i>
            <p>
               &nbsp;Ganosoft
             <b className="caret" />
           </p>
            </Link>
        </li>

        <li className="nav-item">
            <Link to="/Ganosoft_clon" className="nav-link">
            <i> <img src="dist/img/iconos/movil.png" width="35px" /></i>
            <p>
               &nbsp;Crear clon
             <b className="caret" />
           </p>
            </Link>
        </li>
        
         
        <li className="nav-item">
            <a href="https://www.portal.multigobernanza.com/manuales/manual_Ganosoft.pdf" target="blank" className="nav-link">
            <i> <img src="dist/img/iconos/manual.png" width="35px" /></i>
            &nbsp;Manual </a>
        </li>
        <li className="nav-item">
            <Link to="/Administradores" className="nav-link">
            <i> <img src="dist/img/iconos/tutoriales.png" width="35px" /></i>
            <p>
               &nbsp;Tutoriales
             <b className="caret" />
           </p>
            </Link>
        </li>
      </ul> 

      </li>*/}   



       


      





      </ul>
      

     

      <ul className="nav navbar-nav nav-pills nav-sidebar flex-column">
      
        
      </ul>
    
    </nav>
  </div>
 
</nav>
        
    )

    
  }

  


export default Menu
