import React from 'react';
import {HashRouter as Router, Switch, Route} from "react-router-dom";
import history from './components/history';


import MyContextProvider from './contexts/MyContext';
import Home from './components/Home';

import Noticias from './components/Vistas/Noticias/Noticias';
import Multigobernanza from './components/Vistas/Multigobernanza/Multigobernanza';
import Cpanels from './components/Vistas/Cpanels/Cpanels';
import Butyc from './components/Vistas/Butyc/Butyc';
import Butyc_clon from './components/Vistas/Butyc/Butyc_clon';
import Compronline from './components/Vistas/Compronline/Compronline';
import Compronline_clon from './components/Vistas/Compronline/Compronline_clon';
import Softciudadano from './components/Vistas/Softciudadano/Softciudadano';
import Softciudadano_clon from './components/Vistas/Softciudadano/Softciudadano_clon';
import Protecciondedatos from './components/Vistas/Protecciondedatos/Protecciondedatos';
import Protecciondedatos_clon from './components/Vistas/Protecciondedatos/Protecciondedatos_clon';
import Votwin from './components/Vistas/Votwin/Votwin';
import Votwin_clon from './components/Vistas/Votwin/Votwin_clon';
import Softcivico from './components/Vistas/Softcivico/Softcivico';
import Softcivico_clon from './components/Vistas/Softcivico/Softcivico_clon';
import Ganosoft from './components/Vistas/Ganosoft/Ganosoft';
import Ganosoft_clon from './components/Vistas/Ganosoft/Ganosoft_clon';







function App() {
  return (
    <MyContextProvider>
      <Router>
      <Home/>

       <Switch>
           <Route path={'/Noticias'} component = {Noticias}></Route>
           <Route path={'/Multigobernanza'} component = {Multigobernanza}></Route>
           <Route path={'/Cpanels'} component = {Cpanels}></Route>
           <Route path={'/Butyc'} component = {Butyc}></Route>
           <Route path={'/Butyc_clon'} component = {Butyc_clon}></Route>
           <Route path={'/Compronline'} component = {Compronline}></Route>
           <Route path={'/Compronline_clon'} component = {Compronline_clon}></Route>
           <Route path={'/Softciudadano'} component = {Softciudadano}></Route>
           <Route path={'/Softciudadano_clon'} component = {Softciudadano_clon}></Route>
           <Route path={'/Protecciondedatos'} component = {Protecciondedatos}></Route>
           <Route path={'/Protecciondedatos_clon'} component = {Protecciondedatos_clon}></Route>
           <Route path={'/Votwin'} component = {Votwin}></Route>
           <Route path={'/Votwin_clon'} component = {Votwin_clon}></Route>
           <Route path={'/Softcivico'} component = {Softcivico}></Route>
           <Route path={'/Softcivico_clon'} component = {Softcivico_clon}></Route>
           <Route path={'/Ganosoft'} component = {Ganosoft}></Route> 
           <Route path={'/Ganosoft_clon'} component = {Ganosoft_clon}></Route>
           <Route component={Noticias}/>
           <Route history={history}></Route>
         </Switch>


      </Router>
    </MyContextProvider>
  );
}

export default App;
