import React, {useContext} from 'react'
import {MyContext} from '../../../contexts/MyContext'

// Importing the Login & Register Componet
import Register from '../../Register'

function Compronline() {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {
    return (
            <div className="content-wrapper">
    
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h4 className="m-0">COMPRONLINE</h4>
          </div>
          
        </div>
      </div>
    </div>
   

   
    <section className="content">
      <div className="container-fluid">
        
        <div className="row">
        
          <div className="col-md-12">

          <div className="callout callout-info">
          <small className="float-right">Fecha: 15/08/2021</small>
              <h5><i className="fas fa-info"></i> DESCARGAR:</h5>
              <p><b>Plataforma:</b><a href="https://compronline.co/"> Compronline.co</a> </p>
              <p><b>Usuario:</b> pilar@gmail.com </p>
              <p><b>Clave:</b> 12345678 </p> 
            
            
               <div id="qrcode">
               <center><p><b>APP ADMINISTRADOR</b></p></center> 
<img src="https://www.codigos-qr.com/qr/php/qr_img.php?d=https%3A%2F%2Fmega.nz%2Ffile%2FGopxxQBT%23s7-awaJdwBbcQtYQaVzuS0BfCOYkccQAQn2aSXVhhkY&s=6&e=m" alt="Generador de Códigos QR Codes"/>
<br/><a href="https://www.codigos-qr.com/en/qr-code-generator/" target="_blank"></a>
</div> 

 
<div id="qrcode">
             <center><p><b>APP CLIENTE</b></p></center> 
<img src="https://www.codigos-qr.com/qr/php/qr_img.php?d=https%3A%2F%2Fmega.nz%2Ffile%2Fu5wSwDSb%23eRD-XIHG6C1ayAVyXwg8cpeR5_VW1Su0YVNM4RDlsDQ&s=6&e=m" alt="Generador de Códigos QR Codes"/>
<br/><a href="https://www.codigos-qr.com/en/qr-code-generator/" target="_blank"></a>
</div>

<br/> Hola <b>Maria del pilar</b> soy <b>"GIO10"</b> tus apps estan en proceso de publicación en la playstore android y ios , apenas esten listas te envio los QR 
              
            </div>

           
       
          </div>



          <div className="col-md-12">

<div className="callout callout-info">
<small className="float-right">Fecha: 09/09/2021</small>
    <h5><i className="fas fa-info"></i> GRAMADEPORTES:</h5>
    <p><b>Plataforma:</b><a href="https://gramadeportes.com.co/"> gramadeportes.com.co</a> </p>
    <p><b>Usuario:</b> gramadeportes@gmail.com </p>
    <p><b>Clave:</b> 12345678 </p> 
  
    <div id="qrcode">
    <center><p><b>APP ADMINISTRADOR</b></p></center> 
<img src="https://www.codigos-qr.com/qr/php/qr_img.php?d=https%3A%2F%2Fmega.nz%2Ffile%2FftxS2LpC%23yypbVbWJDvit5Cvuk4s9IcRbf6Cryr0fTEOluBaJGB8&s=6&e=m" alt="Generador de Códigos QR Codes"/>
<br/><a href="https://www.codigos-qr.com/en/qr-code-generator/" target="_blank"></a>
</div>

<div id="qrcode">
    <center><p><b>APP ADMINISTRADOR</b></p></center> 
<img src="https://www.codigos-qr.com/qr/php/qr_img.php?d=https%3A%2F%2Fmega.nz%2Ffile%2Fz4RwFJpR%23dk1Tj94gYek0iS6R2NnmsvotOEXg6gNng8AkMyUZzkQ&s=6&e=m" alt="Generador de Códigos QR Codes"/>
<br/><a href="https://www.codigos-qr.com/en/qr-code-generator/" target="_blank"></a>
</div>

<br/> Hola <b>Maria del pilar</b> soy <b>"GIO10"</b> tus apps estan en proceso de publicación en la playstore android y ios , apenas esten listas te envio los QR 
    
  </div>

 

</div>

     

         
        </div>
      

        

      </div>
    </section>
    
  </div>
    )
    }

    else if(showLogin){
        return "";
    }
    else{
        return <Register/>;
    }
    
    }

export default Compronline
