import React, {useContext} from 'react'
import {MyContext} from '../../../contexts/MyContext'

// Importing the Login & Register Componet
import Register from '../../Register'
//import datacenter from '../../../img/datacenter.jpg'


function Protecciondedatos() {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {
    return (
            <div className="content-wrapper">
    
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h4 className="m-0">PROTECCIONDEDATOS</h4>
          </div>
          
        </div>
      </div>
    </div>
   

   
    <section className="content">
      <div className="container-fluid">
        
        <div className="row">
        
          <div className="col-md-12">

          <div className="callout callout-info">
          <small className="float-right">Fecha: 16/6/2021</small>
              <h5><i className="fas fa-info"></i> DATOS DE ACCESO:</h5>
              <b>Usuario: </b> admin  <br />
              <b>Contraseña: </b> 321321321 <b/> <br/> 
              <b><a href="https://protecciondedatos.co" target="blank">PROTECCIONDEDATOS.CO</a> </b> <br/>
              <div id="qrcode">
<img src="https://www.codigos-qr.com/qr/php/qr_img.php?d=https%3A%2F%2Fmega.nz%2Ffile%2FK9YFCIoI%23jyetZd80r9Cn9dlPGUr3uRPO6wFwI1mbdZSzvaeHfsQ&s=6&e=m" alt="Generador de Códigos QR Codes"/>
<br/><a href="https://www.codigos-qr.com/en/qr-code-generator/" target="_blank"></a>
</div>

<br/> Hola <b>Maria del Pilar</b> soy <b>"GIO10"</b> tus apps estan en proceso de publicación en la playstore android y ios , apenas esten listas te envio los QR 
              
            </div>

           
       
          </div>

         
        </div>
      

        

      </div>
    </section>
    
  </div>
    )
    }

    else if(showLogin){
        return "";
    }
    else{
        return <Register/>;
    }
    
    }

export default Protecciondedatos
