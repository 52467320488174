import React, {useContext} from 'react'
import {MyContext} from '../../../contexts/MyContext'

// Importing the Login & Register Componet
import Register from '../../Register'
import logo from '../../../img/noticias/it2.gif'
import datacenter from '../../../img/noticias/datacenter.jpg'


function Noticias() {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {
    return (
            <div className="content-wrapper">
    
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h4 className="m-0">PRINCIPAL</h4>
          </div>
          
        </div>
      </div>
    </div>
   

   
    <section className="content">
      <div className="container-fluid">
        
        <div className="row">
        
          <div className="col-md-12">

          <div className="callout callout-info">
          <small className="float-right">Fecha: 23/6/2021</small>
              <h5><i className="fas fa-info"></i> INTELIGENCIA ARTIFICIAL:</h5>
             <center><img src={logo}  width="120px"/> </center>
              <p>Hola me llamo <b>"GIO10"</b> soy una inteligencia artificial, creare todos lo clones y actualizaciones programadas. </p> 
              <b>Capacidad: </b>
              puedo hacer dos tareas por dia y mis horarios son de oficina. <br />
              <br />Me cuentro ubicado en CONEXCOL en este servidor <br />

      
              <center><img src={datacenter} /> </center>              
              
            </div>

           
       
          </div>

         
        </div>
      

        

      </div>
    </section>
    
  </div>
    )
    }

    else if(showLogin){
        return "";
    }
    else{
        return <Register/>;
    }
    
    }

export default Noticias
