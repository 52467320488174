import React, {useContext} from 'react'
import {MyContext} from '../../../contexts/MyContext'

// Importing the Login & Register Componet
import Register from '../../Register'


function Multigobernanza() {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {
    return (
            <div className="content-wrapper">
    
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h4 className="m-0">Accesos y cuentas</h4>
          </div>
          
        </div>
      </div>
    </div>
   

   
    <section className="content">
      <div className="container-fluid">
        
        <div className="row">
        
          <div className="col-md-12">

          <div className="callout callout-info">
          <small className="float-right">Fecha: 08/03/2023</small>
              <h5><i className="fas fa-info"></i> Dominios</h5>
           {/*   <div class="form-group">
<label>Activar</label>
<select class="form-control">
<option selected>seleccionar</option>
<option disabled>votwin - no se detecto licencia</option>
<option disabled>softcivico - no se detecto licencia</option>
<option disabled>ganosoft - no se detecto licencia</option>
</select>
</div> */}
              <b >Dominios activos  </b><br />
              
              parqueoenvia.com - vence : 20/05/2023 <br/>
              softciudadano.com - vence: 28/04/2023 <br/>
              softciudadano.co.in - vence 01/03/2024 - Nota: Dominio para conexión IA softciudadano <br/> 
              elhabeasdata.co.net.in - vence 01/03/2024 - Nota: Dominio para conexión IA protecciondedatos<br/>          
              fundaciontutic.com - vence 30/03/2023 <br/>
              pisawhat.co.net.in - vence 08/06/2023 - Nota: Dominio para conexión IA pisawhat<br/>
              votwin.com - vence 20/04/2023 <br/>
              softcivico - vence 23/04/2023 <br/>
              pisawhat.co - vence 25/08/2023 <br/>
              protecciondedatos.co - vence 25/08/2023 <br/>


              <br/>
  
{/*<p style={{color:"red"}}>


urotol.com.co<br />
asotrauma.com<br />
estacionamientopublico.com.co<br />
ibalvibra.com.co<br />
veeduriavacunascovid19.com<br />
promoversas.co<br />
ganosoft.com<br />
catalunasslp.com.co<br />
escuelamexicanadeconfiteriaychocolateria.com.co<br />
lineapurpura.com.co<br />
modaartesanalcorzo.com.co<br />
sopranosmusical.com.co<br />
ugosanatorio.com.co<br />
gestoraurbana.com.co<br />
hospitalsanfrancisco.com.co<br />
infibague.com.co<br />
dreamflyescueladeaviacion.com.co<br />
jardinbotanicodebogota.com.co<br />
medicosyenfermeras.com.co<br />
neumologiatolima.com.co<br />
nutraceuticos.com.co<br />
constructoramag.com.co<br />
constructoratorreon.com.co<br />
loteriadeltolima.com.co<br />
systemfuld.com.co<br />
torreondelparque.com.co<br />
academiamusicaleddymusic.com.co<br />
adriana-mastercoach.com.co<br />
cormoda.com.co<br />
losolivostolima.com.co<br />
miltonrestrepo.com.co<br />
playahawai-ibague.com.co<br />
institutovasculardeimagenesdiagnosticas.com.co<br />
mandalahotelconcept.com.co<br />
parquedelarroz<br />
secretariadegobierno.com.co<br />
almacendeportivoprincipe.com.co<br />
conservatoriodeltolima.com.co<br />
kalumay.com.co<br />
marcasactuales.com.co<br />
gramadeportes.com.co<br />
cric.com.co<br />
hectorgordillo-tatis.co<br />
onic.com.co<br />
soyjuancarlosecheverry.co<br />
ustedesmijefe.com.co<br /><br />
tarsoalcaldia.com<br />
el11ideal.com.co<br />
el11idealreality.com<br />
softciudadano.com.co<br />
protecciondedatos.com.co<br /><br />

</p> */}
               <b>Dominios disponibles:</b><br />
               .marketingyapps.com / dominios ilimitados activación inmediata, ejemplo "miweb.marketingyapps.com" <br/>
               .online  / 14 dominios 2 dias habiles para activación , ejemplo "miweb.online" <br/>
               .com  / 10 dominios 4 dias habiles para activación , ejemplo "miweb.com" <br/>
              <br />
            </div>

            
       
          </div>

         
        </div>
      

        

      </div>
    </section>
    
  </div>
    )
    }

    else if(showLogin){
        return "";
    }
    else{
        return <Register/>;
    }
    
    }

export default Multigobernanza
