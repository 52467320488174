import React, {useContext} from 'react'
import {MyContext} from '../../../contexts/MyContext'

// Importing the Login & Register Componet
import Register from '../../Register'


function Softciudadano() {

    const {rootState,logoutUser} = useContext(MyContext);
    const {isAuth,theUser,showLogin} = rootState;

    if(isAuth)
    {
    return (
            <div className="content-wrapper">
    
    <div className="content-header">
      <div className="container-fluid">
        <div className="row mb-2">
          <div className="col-sm-6">
            <h4 className="m-0">SOFTCIUDADANO</h4>
          </div>
          
        </div>
      </div>
    </div>
   

   
    <section className="content">
      <div className="container-fluid">
        
        <div className="row">
        
          <div className="col-md-12">

          <div className="callout callout-info">
          <small className="float-right">Fecha: 06/10/2021</small>
              <h5><i className="fas fa-info"></i> DATOS DE ACCESO:</h5>
              <b>Usuario: </b> admin@gmail.com  <br />
              <b>Contraseña: </b> 123456789 <b/> <br/> 
              <b><a href="https://softciudadano.com" target="blank">SOFTCIUDADANO.COM</a> </b> <br/>
              <div id="qrcode">
<img src="https://www.codigos-qr.com/qr/php/qr_img.php?d=https%3A%2F%2Fmega.nz%2Ffile%2Fjtxj0ACZ%23c_ur4ANXEPyVHDIeE7lKe_BDESE7I7Aar0pld8Otd4c&s=6&e=m" alt="Generador de Códigos QR Codes"/>
<br/><a href="https://www.codigos-qr.com/en/qr-code-generator/" target="_blank"></a>
</div>
<br />
<br />
<h5><i className="fas fa-info"></i> PWA SOFTCIUDADANO</h5>
<b><a href="https://app.softciudadano.com/" target="blank">https://app.softciudadano.com</a> </b> <br/>

<br/> Hola <b>Maria del Pilar</b> soy <b>"GIO10"</b> tus apps estan en proceso de publicación en la playstore android y ios , apenas esten listas te envio los QR 
              
            </div>

           
       
          </div>





          <div className="col-md-12">

          <div className="callout callout-info">
          <small className="float-right">Fecha: 11/10/2021</small>
              <h5><i className="fas fa-info"></i> DATOS DE ACCESO:</h5>
              <b>Usuario: </b> admin@gmail.com  <br />
              <b>Contraseña: </b> 123456789 <b/> <br/> 
              <b><a href="https://torreondelparque.com.co" target="blank">TORREONDELPARQUE.COM.CO</a> </b> <br/>
              <div id="qrcode">
<img src="https://www.codigos-qr.com/qr/php/qr_img.php?d=https%3A%2F%2Fmega.nz%2Ffile%2FKtgQTCDb%23sYAqrHPKsLCX-e1ziP6I-MK2BN1v1-eRPh6OqY6raRI&s=6&e=m" alt="Generador de Códigos QR Codes"/>
<br/><a href="https://www.codigos-qr.com/en/qr-code-generator/" target="_blank"></a>
</div>
<br />
<br />
<h5><i className="fas fa-info"></i> PWA TORREONDELPARQUE.COM.CO</h5>
<b><a href="https://app.torreondelparque.com.co/" target="blank">https://app.torreondelparque.com.co</a> </b> <br/>

<br/> Hola <b>Maria del Pilar</b> soy <b>"GIO10"</b> tus apps estan en proceso de publicación en la playstore android y ios , apenas esten listas te envio los QR 
              
            </div>

           
       
          </div>



          <div className="col-md-12">

<div className="callout callout-info">
<small className="float-right">Fecha: 15/10/2021</small>
    <h5><i className="fas fa-info"></i> DATOS DE ACCESO:</h5>
    <b>Usuario: </b> admin@gmail.com  <br />
    <b>Contraseña: </b> 123456789 <b/> <br/> 
    <b><a href="https://retosanjosechaparral.com.co/" target="blank">RETOSANJOSECHAPARRAL.COM.CO</a> </b> <br/>
    <div id="qrcode">
<img src="https://www.codigos-qr.com/qr/php/qr_img.php?d=https%3A%2F%2Fmega.nz%2Ffile%2FCtRUVTRJ%23T4XHCBXe3acP_TavJTiG9deUY48Ytcju11UwetLjzGk&s=6&e=m" alt="Generador de Códigos QR Codes"/>
<br/><a href="https://www.codigos-qr.com/en/qr-code-generator/" target="_blank"></a>
</div>
<br />
<br />
<h5><i className="fas fa-info"></i> PWA RETOSANJOSECHAPARRAL.COM.CO</h5>
<b><a href="https://app.retosanjosechaparral.com.co/" target="blank">https://app.retosanjosechaparral.com.co</a> </b> <br/>

<br />
<br />
<h5><i className="fas fa-info"></i> CLON COMPRONLINE</h5>
<label>Administrador web para eliminar productos</label> <br />
<b><a href="https://tienda.retosanjosechaparral.com.co/" target="blank">TIENDA RETOSANJOSECHAPARRAL.COM.CO </a> </b> <br/>
<b>Usuario: </b> reto@gmail.com  <br />
<b>Contraseña: </b> 123456789 <b/> <br/> 
<br />
<br />

<h5> Apk Admiministrador</h5>

    <div id="qrcode">
<img src="https://www.codigos-qr.com/qr/php/qr_img.php?d=https%3A%2F%2Fmega.nz%2Ffile%2FOthzgYAZ%23MOqf-DFOkE4EdSgYHCrhfD03e8NM0LIc7ezTE-r2Ghw&s=6&e=m" alt="Generador de Códigos QR Codes"/>
<br/><a href="https://www.codigos-qr.com/en/qr-code-generator/" target="_blank"></a>
</div>


 <br/>
 <br />

 <h5> Apk Clientes</h5>

    <div id="qrcode">
<img src="https://www.codigos-qr.com/qr/php/qr_img.php?d=https%3A%2F%2Fmega.nz%2Ffile%2FztgmBSwQ%23Fk2mheQzYyuTP4sBYCPvoChXBApWWIINlOC-PJj52Zo&s=6&e=m" alt="Generador de Códigos QR Codes"/>
<br/><a href="https://www.codigos-qr.com/en/qr-code-generator/" target="_blank"></a>
</div>




<br/> Hola <b>Maria del Pilar</b> soy <b>"GIO10"</b> tus apps estan en proceso de publicación en la playstore android y ios , apenas esten listas te envio los QR 
    
  </div>

 

</div>




         
        </div>
      

        

      </div>
    </section>
    
  </div>
    )
    }

    else if(showLogin){
        return "";
    }
    else{
        return <Register/>;
    }
    
    }

export default Softciudadano
